.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Reset all link states */
a {
  color: inherit;
  text-decoration: none;
}

a:link, a:visited {
  color: #9b59b6; /* Default color for links */
}

a:hover {
  color: #8e44ad; /* Hover state */
}

a:active {
  color: #6d358c; /* Active state */
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c2c2c;
  color: #fff;
}

.logo a {
  text-decoration: none;
  color: #ffffff; /* Vivid color for the logo */
  font-size: 24px;
  font-weight: bold;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-list li {
  margin-left: 20px;
}

.nav-list a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.nav-list a:link, .nav-list a:visited {
  color: #fff; /* White color for visited state */
}

.nav-list a:hover {
  color: #8e44ad; /* Hover state */
}

.nav-list a:active {
  color: #6d358c; /* Active state */
}

.login-button, .logout-button {
  background-color: #9b59b6; /* Vivid color for the buttons */
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.login-button:hover, .logout-button:hover {
  background-color: #8e44ad; /* Slightly darker shade for hover effect */
}

/* URL Input styles */
.url-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.url-input-form {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the form */
  margin-top: 20px; /* Reduce wasted space */
}

.url-input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.submit-button {
  background-color: #9b59b6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.submit-button:hover {
  background-color: #8e44ad;
}

.error {
  color: red;
  margin-top: 10px;
}

.post-container {
  margin-top: 20px;
  width: 80%;
}



.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.status-message {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}


.sign-in-button {
  font-size: 25px; /* Increased font size */

}