/* index.css */

/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Global styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

h1, h2, h3, p {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

button {
  cursor: pointer;
}

/* Header styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #282c34;
  color: white;
  border-bottom: 1px solid #444;
}

header h1 {
  font-size: 28px;
  margin-right: auto;
}

/* Main content styles */
main {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: calc(100vh - 120px); /* Adjusted to fill more vertical space */
}

/* Footer styles */
footer {
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  text-align: center;
  width: 100%;
}

/* Landing page styles */
.landing-container {
  padding: 40px 20px;
  max-width: 900px; /* Increased width */
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.landing-container p {
  margin-bottom: 20px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.pricing-card {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card h3 {
  margin-bottom: 10px;
}

.pricing-card p {
  margin: 5px 0;
}

.pricing-card .price {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

/* URL input styles */
.url-container {
  width: 100%;
  max-width: 900px; /* Increased width */
  padding: 40px 20px;
  margin: 20px auto;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.url-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.url-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.url-container button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  transition: background-color 0.3s;
}

.url-container button:hover {
  background-color: #4ec3f7;
}

.url-container .error {
  color: red;
  margin-top: 10px;
}

.url-container .post-container {
  text-align: left;
  margin-top: 20px;
}

/* Centering content */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
